<template>
  <v-app class="app-font-class">
    <div>
      <router-view/>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",

  beforeCreate() {
    this.$vuetify.rtl = true;
    if (window.location.pathname !== '/meeting') {
      let token = localStorage.getItem('token')
      if (token) {
        window.axios.get('/user/user').then((res) => {
          this.$store.commit('login', res.data)
          if (this.$route.name === 'Login') {
            this.$router.push('/panel')
          }
        }).catch((err) => {
          if (err.response.status === 401 && this.$route.name !== 'Login') {
            if (this.$route.name) {
              this.$router.push({path: '/login', query: {redirect: window.location.pathname}})
            } else {
              this.$router.push({path: '/login'})
            }
          }
        })
      }
    }
  },
};
</script>
<style>
@font-face {
  font-family: IRANSansFarsi;
  font-style: normal;
  font-weight: 900;
  src: url('assets/fonts/eot/IRANSansWeb(FaNum)_Black.eot');
  src: url('assets/fonts/eot/IRANSansWeb(FaNum)_Black.eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('assets/fonts/woff2/IRANSansWeb(FaNum)_Black.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('assets/fonts/woff/IRANSansWeb(FaNum)_Black.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('assets/fonts/ttf/IRANSansWeb(FaNum)_Black.ttf') format('truetype');
}

@font-face {
  font-family: IRANSansFarsi;
  font-style: normal;
  font-weight: bold;
  src: url('assets/fonts/eot/IRANSansWeb(FaNum)_Bold.eot');
  src: url('assets/fonts/eot/IRANSansWeb(FaNum)_Bold.eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('assets/fonts/woff2/IRANSansWeb(FaNum)_Bold.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('assets/fonts/woff/IRANSansWeb(FaNum)_Bold.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('assets/fonts/ttf/IRANSansWeb(FaNum)_Bold.ttf') format('truetype');
}

@font-face {
  font-family: IRANSansFarsi;
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/eot/IRANSansWeb(FaNum)_Medium.eot');
  src: url('assets/fonts/eot/IRANSansWeb(FaNum)_Medium.eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('assets/fonts/woff2/IRANSansWeb(FaNum)_Medium.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('assets/fonts/woff/IRANSansWeb(FaNum)_Medium.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('assets/fonts/ttf/IRANSansWeb(FaNum)_Medium.ttf') format('truetype');
}

@font-face {
  font-family: IRANSansFarsi;
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/eot/IRANSansWeb(FaNum)_Light.eot');
  src: url('assets/fonts/eot/IRANSansWeb(FaNum)_Light.eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('assets/fonts/woff2/IRANSansWeb(FaNum)_Light.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('assets/fonts/woff/IRANSansWeb(FaNum)_Light.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('assets/fonts/ttf/IRANSansWeb(FaNum)_Light.ttf') format('truetype');
}

@font-face {
  font-family: IRANSansFarsi;
  font-style: normal;
  font-weight: 200;
  src: url('assets/fonts/eot/IRANSansWeb(FaNum)_UltraLight.eot');
  src: url('assets/fonts/eot/IRANSansWeb(FaNum)_UltraLight.eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('assets/fonts/woff2/IRANSansWeb(FaNum)_UltraLight.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('assets/fonts/woff/IRANSansWeb(FaNum)_UltraLight.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('assets/fonts/ttf/IRANSansWeb(FaNum)_UltraLight.ttf') format('truetype');
}

@font-face {
  font-family: IRANSansFarsi;
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/eot/IRANSansWeb(FaNum).eot');
  src: url('assets/fonts/eot/IRANSansWeb(FaNum).eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('assets/fonts/woff2/IRANSansWeb(FaNum).woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('assets/fonts/woff/IRANSansWeb(FaNum).woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('assets/fonts/ttf/IRANSansWeb(FaNum).ttf') format('truetype');
}

/** {*/
/*  font-family: IRANSansFarsi;*/
/*  text-align: right !important;*/
/*}*/

.app-font-class {
  font-family: IRANSansFarsi !important;
  text-align: right !important;
}

.v-pagination__item {
  text-align: center !important;
}

.v-btn {
  letter-spacing: normal !important;
}

.rounded-15 {
  border-radius: 15px !important;
}

.v-tab {
  letter-spacing: normal !important;
}

body {
  position: relative !important;
}

.rtl-font {
  font-family: IRANSansFarsi !important;
  direction: rtl !important;
  text-align: right !important;
}

.ltr-font {
  font-family: Arial !important;
  direction: ltr !important;
  text-align: left !important;
}
</style>