import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        redirect: '/panel'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login'),
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/Register'),
    },
    {
        path: '/panel',
        name: 'Panel',
        component: () => import('../views/Panel'),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'video',
                name: 'Videos',
                component: () => import('../views/Videos'),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'frame',
                name: 'Frames',
                component: () => import('../views/Frames'),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'image',
                name: 'Images',
                component: () => import('../views/Images'),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'plans',
                name: 'Plans',
                component: () => import('../views/Plans'),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'my-plans',
                name: 'MyPlans',
                component: () => import('../views/MyPlans'),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'orders',
                name: 'Orders',
                component: () => import('../views/Orders'),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'profile',
                name: 'Profile',
                component: () => import('../views/Profile'),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'document',
                name: 'ApiDocument',
                component: () => import('../views/ApiDocument'),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'meeting',
                name: 'Meeting',
                component: () => import('../views/Meeting'),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'meeting/:unique_channel',
                name: 'Meet',
                component: () => import('../views/Meet'),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'rtmp/:rtmp_link',
                name: 'Rtmp',
                component: () => import('../views/Rtmp'),
                meta: {
                    requiresAuth: true
                },
            },
        ]
    },
    {
        path: '/meeting',
        name: 'MeetingIframe',
        component: () => import('../views/Iframe/Meeting'),
    },
    {
        path: '/meeting/ltr',
        name: 'MeetingIframeLtr',
        component: () => import('../views/Iframe/Meeting'),
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        if (!localStorage.getItem('token')) {
            next({path: '/login', query: {redirect: to.path}})
        }
    }

    return next();
})

export default router
