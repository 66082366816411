import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import './plugins/axios'
import Paintable from 'vue-paintable';
import i18n from './i18n'

window.Pusher = require('pusher-js');

Vue.mixin({
  data() {
    return {
      snackbarStatus: false,
      snackbarTimeout: 5000,
      snackbarText: '',
      snackbarColor: 'success',
      mobile: window.innerWidth <= 700,
      baseURL: process.env.VUE_APP_BASE_URL,
    }
  },
  methods: {
    openSnackbar(text, color = 'success', timeout = 5000) {
      this.snackbarStatus = true
      this.snackbarText = text
      this.snackbarColor = color
      this.snackbarTimeout = timeout
    },
    showValidationErrors(errors) {
      let errorText = ''
      Object.entries(errors).forEach((err) => {
        err[1].forEach((item) => {
          errorText = errorText + item + '<br>'
        })
      })
      this.openSnackbar(errorText, '#F1416C')
    }
  },
  watch: {},
})

Vue.use(Paintable, {
  setItem(key, image) {
    localStorage.setItem(key, image);
  },
  getItem(key) {
    return localStorage.getItem(key);
  },
  removeItem(key) {
    localStorage.removeItem(key);
  }
});

Vue.config.productionTip = false
new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
